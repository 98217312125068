import { PhoneIcon, useModal } from '@pancakeswap/uikit'
import HelpDeskModal from './HelpDeskModal'
import { HelpDeskContainer, HelpDeskIcon } from './styles'

function HelpDesk() {
  const [openModal] = useModal(<HelpDeskModal />)
  return (
    <HelpDeskContainer>
      <HelpDeskIcon onClick={openModal}>
        <PhoneIcon />
      </HelpDeskIcon>
    </HelpDeskContainer>
  )
}

export default HelpDesk
