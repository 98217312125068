import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
    <g clipPath="url(#clip0_2824_9841)">
      <path
        d="M4.16667 0C2.14427 0 0.5 1.56953 0.5 3.5V24.5C0.5 26.4305 2.14427 28 4.16667 28H18.8333C20.8557 28 22.5 26.4305 22.5 24.5V8.75H15.1667C14.1526 8.75 13.3333 7.96797 13.3333 7V0H4.16667ZM15.1667 0V7H22.5L15.1667 0ZM4.16667 14C4.16667 13.5359 4.35982 13.0908 4.70364 12.7626C5.04745 12.4344 5.51377 12.25 6 12.25C6.48623 12.25 6.95255 12.4344 7.29636 12.7626C7.64018 13.0908 7.83333 13.5359 7.83333 14C7.83333 14.4641 7.64018 14.9092 7.29636 15.2374C6.95255 15.5656 6.48623 15.75 6 15.75C5.51377 15.75 5.04745 15.5656 4.70364 15.2374C4.35982 14.9092 4.16667 14.4641 4.16667 14ZM12.875 15.75C13.1786 15.75 13.4594 15.8922 13.6313 16.1273L18.6729 23.1273C18.8677 23.3953 18.8849 23.7453 18.7302 24.0297C18.5755 24.3141 18.2604 24.5 17.9167 24.5H5.08333C4.75104 24.5 4.4474 24.3305 4.28698 24.057C4.12656 23.7836 4.12656 23.4445 4.29844 23.1766L7.04844 18.8016C7.21458 18.5391 7.5125 18.375 7.83333 18.375C8.15417 18.375 8.45208 18.5336 8.61823 18.8016L9.35156 19.9719L12.1188 16.1328C12.2906 15.8977 12.5714 15.7555 12.875 15.7555V15.75Z"
        fill="#DFE7F1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2824_9841">
        <rect width="22" height="28" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default Icon;
