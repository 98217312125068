import { Button, Close2Icon, CloseV2Icon, FileIcon, Flex, Text } from '@pancakeswap/uikit'
import ModalV3 from '@pancakeswap/uikit/src/widgets/Modal/ModalV3'
import React, { useRef, useState } from 'react'
import { FieldFileItem, FieldItem, FieldTextAreItem, LoadingBarItem, LoadingProcess, TextCustom } from './styles'

interface ModalProps {
  onDismiss?: () => void
}

enum TypeImage {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
}

const HelpDeskModal: React.FC<React.PropsWithChildren<ModalProps>> = ({ onDismiss }) => {
  return (
    <ModalV3
      title=""
      onDismiss={() => {
        onDismiss()
      }}
      style={{ position: 'relative' }}
      maxWidth={['90%', '90%', 420]}
    >
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        style={{ gap: '0.5rem' }}
      >
        <TextCustom onClick={() => onDismiss()}>
          <Close2Icon />
        </TextCustom>
      </Flex>
      <Text fontSize={[20, null, 24]} fontWeight={800} color="#1A202C" textAlign="center">
        Hỗ trợ
      </Text>
      <Flex flexDirection="column" overflow="auto" style={{ gap: '14px' }}>
        <FieldInputText title="Họ tên" placeholder="Họ tên" />
        <FieldInputText title="Số điện thoại" placeholder="Số điện thoại" />
        <FieldInputText title="Email" placeholder="Email" />
        <FieldInputTextArea title="Mô tả" placeholder="Nhập nội dung cần hỗ trợ" />
        <FieldInputTextFile title="Hình ảnh nếu có (không bắt buộc)" />
      </Flex>
      <Button marginTop={24}>
        <Text fontSize={14} fontWeight={800} color="#ffffff">
          Xác Nhận
        </Text>
      </Button>
    </ModalV3>
  )
}

export default HelpDeskModal

const FieldInputText = ({ title, onChange, placeholder }: { title: string; onChange?: any; placeholder?: string }) => {
  return (
    <Flex flexDirection="column">
      <Text fontSize={[12, null, 14]} fontWeight={800} color="#718096">
        {title}
      </Text>
      <FieldItem placeholder={placeholder} onChange={(e) => onChange(e?.target?.value)} />
    </Flex>
  )
}

const FieldInputTextArea = ({
  title,
  onChange,
  placeholder,
}: {
  title: string
  onChange?: any
  placeholder?: string
}) => {
  return (
    <Flex flexDirection="column">
      <Text fontSize={[12, null, 14]} fontWeight={800} color="#718096">
        {title}
      </Text>
      <FieldTextAreItem rows={3} placeholder={placeholder} onChange={(e) => onChange(e?.target?.value)} />
    </Flex>
  )
}

const FieldInputTextFile = ({
  title,
  onChange,
  placeholder,
}: {
  title: string
  onChange?: any
  placeholder?: string
}) => {
  const [image, setImage] = useState(null)
  const [error, setError] = useState('')
  const inputRef = useRef(null)

  const handleDrop = (e: any, isInput?: boolean) => {
    e.preventDefault()
    const file = !isInput ? e.dataTransfer.files[0] : e.target.files[0]
    if (file?.type !== TypeImage.PNG && file?.type !== TypeImage.JPEG && file?.type !== TypeImage.JPG) {
      setError('Chỉ hổ trợ file PNG, JPEG, JPG')
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      setImage(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <Flex flexDirection="column">
      <Text fontSize={[12, null, 14]} fontWeight={800} color="#718096">
        {title}
      </Text>
      <FieldFileItem onDrop={handleDrop} onDragOver={handleDragOver}>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <FileIcon />
          <Text fontSize={10} fontWeight={500}>
            Thả hình ảnh của bạn ở đây, hoặc{' '}
            <Text
              fontSize={10}
              color="#3182CE"
              style={{ display: 'inline-block', cursor: 'pointer' }}
              onClick={() => inputRef?.current?.click()}
            >
              tải lên
            </Text>
          </Text>
          <Text fontSize="8px" fontWeight={500} color="#718096">
            Hổ trợ file PNG, JPEG, JPG
          </Text>
        </Flex>
        <FieldItem ref={inputRef} type="file" style={{ display: 'none' }} onChange={(e) => handleDrop(e, true)} />
      </FieldFileItem>
      {error.length > 0 && (
        <Text fontSize={12} color="#FB1919" lineHeight="26px">
          {error}
        </Text>
      )}
      <Flex
        width="100%"
        flexDirection="column"
        marginTop={14}
        padding="8px 16px"
        borderRadius={8}
        border="1px solid #E2E8F0"
      >
        <Flex width="100%" justifyContent="space-between">
          <Text fontSize={12} fontWeight={700}>
            Đang tải ảnh...
          </Text>
          <Text style={{ cursor: 'pointer' }}>
            <CloseV2Icon />
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Text fontSize={10} fontWeight={500} color="#A0AEC0">
            50% - còn 1 phút
          </Text>
          <LoadingBar />
        </Flex>
      </Flex>
    </Flex>
  )
}

const LoadingBar = () => {
  const [progress, setProgress] = useState(30)

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (progress >= 100) {
  //       setProgress(100)
  //     } else {
  //       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 30))
  //     }
  //   }, 800)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  return (
    <LoadingBarItem>
      <LoadingProcess style={{ width: `${progress >= 100 ? 100 : progress}%` }} />
    </LoadingBarItem>
  )
}
