import { Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

const TextCustom = styled.p`
  font-size: 16px;
  color: #718096;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Privacy = () => {
  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" marginTop={195}>
      <TextCustom color="textSubtle">Privacy Policy</TextCustom>
      <TextCustom color="textSubtle">Copyright 2023</TextCustom>
    </Flex>
  )
}
export default Privacy
