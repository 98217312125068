import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = () => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="eva:phone-call-fill">
        <g id="evaPhoneCallFill0">
          <g id="evaPhoneCallFill1">
            <g id="evaPhoneCallFill2">
              <path
                id="Vector"
                d="M17.8333 10.6667C18.8942 10.6667 19.9116 11.0881 20.6618 11.8382C21.4119 12.5884 21.8333 13.6058 21.8333 14.6667C21.8333 15.0203 21.9738 15.3594 22.2239 15.6095C22.4739 15.8595 22.813 16 23.1667 16C23.5203 16 23.8594 15.8595 24.1095 15.6095C24.3595 15.3594 24.5 15.0203 24.5 14.6667C24.5 12.8986 23.7976 11.2029 22.5474 9.95262C21.2971 8.70238 19.6014 8 17.8333 8C17.4797 8 17.1406 8.14048 16.8905 8.39052C16.6405 8.64057 16.5 8.97971 16.5 9.33333C16.5 9.68696 16.6405 10.0261 16.8905 10.2761C17.1406 10.5262 17.4797 10.6667 17.8333 10.6667Z"
                fill="#5D80FB"
              />
              <path
                id="Vector_2"
                d="M17.8332 5.33366C20.3085 5.33366 22.6825 6.31699 24.4328 8.06733C26.1832 9.81767 27.1665 12.1916 27.1665 14.667C27.1665 15.0206 27.307 15.3598 27.557 15.6098C27.8071 15.8598 28.1462 16.0003 28.4998 16.0003C28.8535 16.0003 29.1926 15.8598 29.4426 15.6098C29.6927 15.3598 29.8332 15.0206 29.8332 14.667C29.8332 11.4844 28.5689 8.43215 26.3185 6.18171C24.068 3.93127 21.0158 2.66699 17.8332 2.66699C17.4795 2.66699 17.1404 2.80747 16.8904 3.05752C16.6403 3.30756 16.4998 3.6467 16.4998 4.00033C16.4998 4.35395 16.6403 4.69309 16.8904 4.94313C17.1404 5.19318 17.4795 5.33366 17.8332 5.33366ZM29.4998 21.2137C29.4265 20.9996 29.2999 20.8078 29.132 20.6561C28.964 20.5045 28.7602 20.3981 28.5398 20.347L20.5398 18.5203C20.3227 18.4711 20.0967 18.477 19.8824 18.5376C19.6681 18.5981 19.4724 18.7114 19.3132 18.867C19.1265 19.0403 19.1132 19.0537 18.2465 20.707C15.3708 19.3819 13.0665 17.0681 11.7532 14.187C13.4465 13.3337 13.4598 13.3337 13.6332 13.1337C13.7888 12.9744 13.902 12.7787 13.9626 12.5644C14.0231 12.3502 14.0291 12.1241 13.9798 11.907L12.1532 4.00033C12.102 3.77992 11.9956 3.57615 11.844 3.40821C11.6924 3.24026 11.5006 3.11366 11.2865 3.04033C10.9751 2.92911 10.6536 2.84873 10.3265 2.80033C9.98951 2.72219 9.64561 2.67752 9.29984 2.66699C7.67318 2.66699 6.11314 3.31318 4.96292 4.4634C3.81269 5.61363 3.1665 7.17366 3.1665 8.80033C3.17356 14.2439 5.33915 19.4626 9.18837 23.3118C13.0376 27.161 18.2562 29.3266 23.6998 29.3337C24.5053 29.3337 25.3028 29.175 26.047 28.8668C26.7911 28.5586 27.4672 28.1068 28.0368 27.5372C28.6063 26.9677 29.0581 26.2916 29.3663 25.5475C29.6745 24.8033 29.8332 24.0058 29.8332 23.2003C29.8336 22.861 29.8068 22.5221 29.7532 22.187C29.6971 21.8558 29.6124 21.5301 29.4998 21.2137Z"
                fill="#194BFB"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Icon;
