import styled from 'styled-components'

export const HelpDeskContainer = styled.div`
  width: 100%;
`

export const TextCustom = styled.div`
  cursor: pointer;
`

export const HelpDeskIcon = styled.div`
  display: grid;
  place-content: center;
  position: absolute;
  right: 24px;
  bottom: 100px;
  width: 64px;
  height: 64px;
  border-radius: 64px;
  background: #fff;
  box-shadow: 4px 4px 24px 0px rgba(103, 103, 103, 0.1);
  cursor: pointer;
  transition: all 0.2s linear;

  &:active {
    transform: scale(0.9);
  }
`

export const HelpDeskContent = styled.div``

export const FieldItem = styled.input`
  width: 100%;
  padding: 14px 16px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  color: #1a202c;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a0aec0;
    font-size: 14px;
    font-weight: 500;
  }
`

export const FieldTextAreItem = styled.textarea`
  width: 100%;
  padding: 14px 16px;
  border-radius: 10px;
  border: 1px solid #e2e8f0;
  background-color: #ffffff;
  color: #1a202c;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #a0aec0;
    font-size: 14px;
    font-weight: 500;
  }
`

export const FieldFileItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 37px 15px 37px;
  border-radius: 8px;
  border: 1px dashed #194bfb;
`

export const LoadingBarItem = styled.div`
  width: 100%;
  max-width: 155px;
  height: 4px;
  background-color: #e2e8f0;
  border-radius: 5px;
`

export const LoadingProcess = styled.div`
  height: 4px;
  background-color: #194bfb;
  border-radius: 5px;
  transition: 0.2s linear;
`
