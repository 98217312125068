import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill="#FB1919" fillOpacity="0.1" />
    <path d="M10.4001 5.56396L5.6001 10.436" stroke="#FB1919" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.6001 5.56396L10.4001 10.436" stroke="#FB1919" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
