import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = () => (
  <svg width="30" height="30" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="plus">
      <g id="Group">
        <path
          id="Vector"
          d="M16.9492 7.55078L7.04972 17.4503"
          stroke="#194BFB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.0498 7.55078L16.9493 17.4503"
          stroke="#194BFB"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default Icon;
